import './blog-section.module.scss';
import { Component } from 'react';

/* eslint-disable-next-line */
export interface BlogSectionProps {
  content?: string;
  contentImage?: string;
  reverseRow?;
  id?: string;
}

class BlogSection extends Component<BlogSectionProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { content, contentImage, reverseRow, id } = this.props;
    return (
      <div
        id={id}
        className={
          reverseRow === true
            ? 'row flex-row-reverse blog-sec-row'
            : 'row blog-sec-row'
        }
      >
        <div className="col-md-7">
          <div className="content-image">
            {contentImage && <img src={contentImage} alt="" />}
          </div>
        </div>
        <div className="col-md-5">
          <div className="content">{content}</div>
        </div>
      </div>
    );
  }
}

export default BlogSection;
