import './index.module.scss';
import Layout from '../../layout';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import BannerInner from '../../components/banner-inner/banner-inner';
import ContactForm from '../../components/contact-form/contact-form';
import BlogSection from '../../components/blog-section/blog-section';
import bannerImage from '../../assets/images/special-banner.jpg';
import owner from '../../assets/images/owner.jpg';
import love from '../../assets/images/love-disabl.jpg';
import executive from '../../assets/images/executive.jpg';
import medical from '../../assets/images/medical-prof.jpg';
import estate from '../../assets/images/real-estate.jpg';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={bannerImage}
        caption={
          <div>
            <h2 className="x-large-heading color-white">Our Specialties</h2>
          </div>
        }
      />
      <div className="container">
        <BlogSection
          id={'business'}
          contentImage={owner}
          reverseRow={true}
          content={
            <div>
              <h3 className="medium-heading">Business Owners</h3>
              <p className="color-light-grey">
                As an entrepreneur, we know you have to have a long-term vision
                and wear a lot of hats. We work with business owners like
                yourself to help crystallize that long-term vision of yours into
                an actionable series of processes to bring you success. We can
                help through proper valuation of your company, increasing cash
                flow and developing a succession strategy. DGM will help you
                create a path for success today that will pay dividends in the
                future.{' '}
              </p>
            </div>
          }
        />
        <BlogSection
          id={'disablities'}
          contentImage={love}
          content={
            <div>
              <h3 className="medium-heading">Loved One With Disabilities</h3>
              <p className="color-light-grey">
                From personal experience, we know what it is like when you have
                a family member living with a disability. We have experience in
                managing trusts for loved ones and in navigating many of the
                state demands in doing so. . We can create a plan designed to
                provide for your loved ones today and in the future. This plan
                would also feature strategies to help ensure your loved one
                receives support from all potential resources.
              </p>
            </div>
          }
        />
        <BlogSection
          id={'executives'}
          reverseRow={true}
          contentImage={executive}
          content={
            <div>
              <h3 className="medium-heading">Executives</h3>
              <p className="color-light-grey">
                As a business leader, you're likely a person who sees the big
                picture. DGM will offer specific services for you and your
                skillset, including negotiating benefits packages, establishing
                tax efficient estate planning and trust plans, tax strategies
                for charitable giving, multi state tax filing and wealth
                transfer, and a wealth plan that takes into consideration the
                uniqueness of your compensation package, all to help you
                accumulate, preserve and pass along your wealth in a way that
                creates the legacy you desire.
              </p>
            </div>
          }
        />
        <BlogSection
          id={'medical'}
          contentImage={medical}
          content={
            <div>
              <h3 className="medium-heading">Medical Professionals </h3>
              <p className="color-light-grey">
                In our experiences, no professional is busier than a doctor;
                often, their commitment to take care of others leaves little
                time to take care of their own finances and retirement plans. We
                offer strategies for physicians and other medical professionals
                like yourself that includes wealth management, charitable
                giving, asset protection and estate planning to help you provide
                the peace of mind that you provide your patients with their
                health and well-being.
              </p>
            </div>
          }
        />
        <BlogSection
          id={'commercial'}
          reverseRow={true}
          contentImage={estate}
          content={
            <div>
              <h3 className="medium-heading">Commercial Real Estate</h3>
              <p className="color-light-grey">
                If you are a commercial real estate investor, you often have a
                unique set of challenges. Most often, that may be an
                overweighting in the asset at the expense of diversification in
                other areas. Transitioning these assets to a corporate structure
                can be paramount to ensuring that the next generation is taken
                care of, as governance is an often overlooked way to protect
                family wealth and preserve your legacy for generations to come.
              </p>
            </div>
          }
        />
      </div>

      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
